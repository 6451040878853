import styled from 'styled-components';

export const WebAppWrapper = styled.div`
    font-family: 'Montserrat', 'OpenSans-Light', Tahoma, "Helvetica Neue", Helvetica, Arial, sans-serif;
    display: grid;
    grid-template-columns: 10px 1fr 10px;
    grid-template-rows: 2em 1fr 1fr;
    /* grid-template-rows: 3em minmax(4em, auto) minmax(4em, auto); */
    /* max-width: 1080px;
    width: 80vw; */
    height: 100%;
    /* background-color: #fff; */
    /* margin: 0 auto; */
    /* padding-bottom: 50px; */
    
    @media (min-width: 50em) {
        grid-template-columns: 1fr 4fr 1fr;
        grid-template-rows: 2em 1fr minmax(4em, auto);
      /* grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-template-rows: 250px 250px; */
    }
`;
export const AppContainer = styled.div`
    /* font-family: 'Didact Gothic', 'OpenSans-Light', Tahoma, "Helvetica Neue", Helvetica, Arial, sans-serif; */
    
    grid-row: 2;
    grid-column: 2;
    display: grid;
    grid-template-rows: minmax(6em, 25em) minmax(5em, auto) 1fr;
    /* grid-template-rows: repeat(auto-fit, minmax(5em, auto)); */

    /* @media (max-width: 799px) {
        min-height: 1000px;
    } */
    /* grid-template-rows: repeat(3, ) minmax(5em, auto) minmax(2em, auto) 4fr; */
    /* max-width: 1080px;
    width: 80vw;
    min-height: 100vh;
    background-color: #fff;
    margin: 0 auto;
    padding-bottom: 50px; */
`;