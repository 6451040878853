import { useState } from 'react';
import { DisplayContainer, DisplayGridContainer, TextWrapper } from './DisplayGrid.styles';
import Modal from '../Modal/Modal';
import { ContentDisplayGridArray as contentArray } from '../../../data/DisplayGridData';

const DisplayGrid = () => {

    const [selectedImg, setSelectedImg] = useState({
        src: null,
        alt: null
    });
    const [selectedText, setSelectedText] = useState({
        bgColor: null,
        textColor: null,
        content: null,
        title: null
    });

    const [showModal, setShowModal] = useState(false);
    




    const handleImgClick = (e, type, description, title, bgColor, textColor) => {
        // console.log(`!!!!! in handleImgClick ... type is: ${type} !!!!!`);
        // console.log(`!!!!! in handleImgClick ... e is: ${e} !!!!!`);
        const eventTarget = e.target;
        if (type === 'image') {
            setSelectedImg({
                src: eventTarget.src,
                alt: eventTarget.alt
            });
            setSelectedText({
                bgColor: null,
                textColor: null,
                content: null,
                title: null
            });
        }
        else if (type === 'text') {
            // console.log(`$$$$$ in handleImgClick ... bgColor is: ${bgColor} $$$$$`);
            // console.log(`$$$$$ in handleImgClick ... description is: ${description} $$$$$`);
            // console.log(`$$$$$ in handleImgClick ... title is: ${title} $$$$$`);
            setSelectedText({
                bgColor: bgColor,
                textColor: textColor,
                content: description,
                title: title
            });
            setSelectedImg({
                src: null,
                alt: null
            });
        }
        setShowModal(true);
    };

    // { contentArray && contentArray.sort((a, b) => {return a.displayOrder - b.displayOrder}).map( content => (
    //     <DisplayContainer banner={content.displayBanner} layout={content.layout} bgColor={content.bgColor} key={content.name} >
    //         {
    //         (content.src) ?
    //             <img src={ content.src } alt={ content.alt } key={ content.id } onClick={handleImgClick} /> :
    //             <TextWrapper>{content.text}</TextWrapper>
    //         }
    //     </DisplayContainer>
    //     )
    //     )
    // }
    
    return ( 
        <DisplayGridContainer>
            { contentArray && contentArray.sort((a, b) => {return a.row - b.row})
                    .map(row => row.items.sort((a, b) => {return a.displayOrder - b.displayOrder})
                            .map( content => (
                                <DisplayContainer banner={content.displayBanner} layout={content.layout} bgColor={row.bgColor} textColor={row.textColor} key={content.name} >
                                    {
                                    (content.src) ?
                                        <img src={ content.src } alt={ content.alt } key={ content.id } onClick={(e) => handleImgClick(e,'image')} /> :
                                        <TextWrapper key={ content.id } onClick={(e) => handleImgClick(e, 'text', content.description, content.text, row.bgColor, row.textColor )}>{content.text}</TextWrapper>
                                    }
                                </DisplayContainer>
                                // image.gridSpan === 'banner' ? {gridColumn:1/3} : {}}>
                                )
                            )
                    )
            }

        { showModal && <Modal setShowModal={setShowModal} image={selectedImg} text={selectedText} />}
        </DisplayGridContainer>
    );
    }

export default DisplayGrid;