import React from 'react'

import { AppContainer, WebAppWrapper } from './App.styles';

import { ArtGrid, Title, Intro, BottomDisplayBlock } from './comps';
import { TopFrame } from './comps/display/TopFrame.styles';
function App() {
  return (
    <WebAppWrapper>
      <TopFrame />
        <AppContainer>
          <Title />
          <Intro />
          <ArtGrid />
        </AppContainer>
      <BottomDisplayBlock />
    </WebAppWrapper>
  );
}

export default App;
