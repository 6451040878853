import styled from "styled-components";
import { gradualAppear } from "../Animations.styles";

export const IntroWrapper = styled.div`
    max-width: 80%;
    grid-row: 2;
    text-align: center;
    margin: auto;
    margin-bottom: 2em;
    font-size: 1em;
    /* font-style: italic; */
    /* letter-spacing: 0.05em;
    word-spacing: 0.2em; */
    color: #595857;
    line-height: 1.6em;
    animation: ${gradualAppear} 0.6s ease-in-out;

    p {
        margin-top: 1em;
    }
`;