import { HeaderImageHolder, Heading, LogoHolder, TitleContainer } from "./Title.styles";
import logo from '../../../images/Cuthbert&Co_NewGrey.svg';
import HeaderBanner from '../../../images/MarketingBanner_Home.jpg';

const Title = () => {

    const h1Main = `Bespoke art for the people in your life`;
    const h1Sub = `"Art with Heart"`
    return ( 
        // <header>
            <TitleContainer>
                <HeaderImageHolder>
                    <img className='header-image' src={HeaderBanner} alt="an example of our Home Portraits framed and situated with plants on a shelf" />
                </HeaderImageHolder>
                <LogoHolder>
                    <img src={logo} alt="Cuthbert & Co." />
                    <h2>{h1Sub}</h2>
                </LogoHolder>
                <Heading>
                <h1>{h1Main}</h1>
                </Heading>
            </TitleContainer>
    //   </header>
    );
}
 
export default Title;