import babushkaSmithsImg from '../images/BabushkaPromoImage_InteriorMockUp1_Smiths_A4size_2.jpg';
import babushkaPearsonsImg from '../images/BabushkaPromoImage_InteriorMockUp2_Pearsons_A4size_2.jpg';
import babushkaImg from '../images/BabushkaPromoImage_GrandChildren_A4size_2_Good25pc.jpg';

import leonImg from '../images/Leon_promo.jpg';
import petNanaOriginalPhotoImg from '../images/NanaFramed-withOriginalPhoto.jpg';
import petLeonLadyHoldingImg from '../images/LeonFramed-ladyHolding.jpg';

import houseImg from '../images/Home_promo_377WHL.jpg';
import homeLadyHangingPicture from '../images/HomePromo_LadyHangingPicture_TheGreens377WHL.jpg';
import homeLadyHangingPictureYellowT from '../images/HomePromo_LadyHangingPic_YellowTShirt.jpg';

import posterImg from '../images/Poster_Dance.jpg';
import posterLadyHoldingImg from '../images/PostersPromo_LadyHolding.jpg';
import posterOnShelfImg from '../images/PosterPromo_onShelf.jpg';


export const ContentDisplayGridArray = [
    {
        row: 2,
        bgColor: '#6eabb0',
        textColor: '#fcf8f5',
        items: [{
            name: 'Framed Pet Portrait - Nana',
            id: 1,
            src: petNanaOriginalPhotoImg,
            alt: 'Example of framed bespoke artwork of your pet - showing the original photo too - Nana rescue dog',
            displayBanner: false,
            layout: 'portrait',
            displayOrder: 2,
            overText: '',
            text: '',
            description: ''
        },
        {
            name: 'Pets - Unframed Image Leon',
            id: 2,
            src: leonImg,
            alt: 'An example of bespoke image of your pet, unframed - Leon rescue dog',
            displayBanner: false,
            layout: 'portrait',
            displayOrder: 4,
            overText: '',
            text: '',
            description: ''
        },
        {
            name: 'Pets category description',
            id: 3,
            src: null,
            alt: 'The text to describe the bespoke image of your pet',
            displayBanner: false,
            layout: 'portrait',
            displayOrder: 3,
            overText: '',
            text: 'Pets',
            description: '<p>Capture a canine companion, feline friend, hamster homie or any pet pal from a favourite photo of them.</p><p>Drawn, from the photo provided, at our home-based studio Cuthbert & Co (aka Andy & family) want to capture the heart & essence of your furry friends forever in a work of art.</p><p>We started this with our own pups and are pleased to introduce to you, our handsome hound Leon and our pretty pup Nana who have taken up residence in our home & hearts and on our walls now!</p>'
        },
        {
            name: 'Framed Pet Portrait - Leon',
            id: 4,
            src: petLeonLadyHoldingImg,
            alt: 'An example of framed bespoke artwork of your pet - Leon rescue dog',
            displayBanner: false,
            layout: 'landscape',
            displayOrder: 1,
            overText: '',
            text: '',
            description: ''
        }
    ]
},
    {
        row: 3,
        bgColor: '#b6cfbc',
        textColor: '#595857',
        items: [{
            name: 'Home - Unframed Home Image',
            id: 1,
            src: houseImg,
            alt: 'Bespoke image of your home',
            displayBanner: false,
            layout: 'portrait',
            displayOrder: 3,
            overText: '',
            text: '',
            description: ''
            // bgColor: ''
        },
        {
            name: 'Home - Lady Smiling Hanging Framed Picture',
            id: 2,
            src: homeLadyHangingPicture,
            alt: 'A lady smiling while hanging a framed picture of her home',
            displayBanner: false,
            layout: 'portrait',
            displayOrder: 1,
            overText: '',
            text: '',
            description: ''
            // bgColor: ''
        },
        {
            name: 'Places Category Description',
            id: 3,
            src: null,
            alt: 'The text to describe the bespoke image of your home (place)',
            displayBanner: false,
            layout: 'portrait',
            displayOrder: 4,
            overText: '',
            text: 'Places',
            description: '<p>Turn an abode into art; a dwelling into design; a special place into a special piece of art.</p>'
            // bgColor: '#6eabb0'
        },
        {
            name: 'Home - Lady Wearing Yellow T-Shirt Hanging Framed Picture',
            id: 2,
            src: homeLadyHangingPictureYellowT,
            alt: 'A lady smiling while hanging a framed picture of her home',
            displayBanner: false,
            layout: 'portrait',
            displayOrder: 2,
            overText: '',
            text: '',
            description: ''
            // bgColor: ''
        }
    ]
},
{
    row: 1,
    bgColor: '#f9ecb7',
    textColor: '#595857',
    items: [{
            name: 'Framed Babushka Bunch - Pearsons',
            id: 1,
            src: babushkaPearsonsImg,
            alt: 'An example tribe - a framed example of the Babushka dolls - The Pearsons Family',
            displayBanner: false,
            layout: 'landscape',
            displayOrder: 4,
            overText: '',
            text: '',
            description: ''
        },
        {
            name: 'Framed Babushka Bunch - Smiths',
            id: 4,
            src: babushkaSmithsImg,
            alt: 'Example framed bespoke image of your Babushka Bunch - The Smiths Family',
            displayBanner: false,
            layout: 'landscape',
            displayOrder: 3,
            overText: '',
            text: '',
            description: ''
        },
        {
            name: 'People Category description',
            id: 3,
            src: null,
            alt: 'The text to describe the bespoke image of your tribe.',
            displayBanner: false,
            layout: 'portrait',
            displayOrder: 1,
            overText: '',
            text: 'People',
            description: '<p>After my wife’s lifelong obsession for Babushka Dolls, finally for her 40th birthday, I drew our family as a set. This then turned into lots of requests from friends and family for their very own picture of their families.</p><p>For families, friends or groups of any description our dolls can be personalised to capture your individuality &amp; connection.</p>'
        },
        {
            name: 'Babushka Bunch - Grandchildren',
            id: 4,
            src: babushkaImg,
            alt: 'Bespoke image of your Babushka Bunch',
            displayBanner: false,
            layout: 'landscape',
            displayOrder: 2,
            overText: '',
            text: '',
            description: ''
        }
    ]
},
{
    row: 4,
    bgColor: '#f0c9a2',
    textColor: '#595857',
    items: [{
            name: 'Framed Poster - lady holding',
            id: 1,
            src: posterLadyHoldingImg,
            alt: 'The framed dance poster being held and presented by a woman',
            displayBanner: false,
            layout: 'landscape',
            displayOrder: 4,
            overText: '',
            text: '',
            description: ''
            // bgColor: ''
        },
        {
            name: 'Framed Poster On Shelf - Dancer',
            id: 4,
            src: posterOnShelfImg,
            alt: 'Example framed poster on a shelf with books and a bird ornament - The Dancer',
            displayBanner: false,
            layout: 'landscape',
            displayOrder: 3,
            overText: '',
            text: '',
            description: ''
            // bgColor: ''
        },
        {
            name: 'Posters Category description',
            id: 3,
            src: null,
            alt: 'Posters',
            displayBanner: false,
            layout: 'portrait',
            displayOrder: 2,
            overText: '',
            text: 'Posters',
            description: "<p>Simply put, some of our personal favourite images for you to have too!</p><p>Pick for yourself or as a gift!</p><p>We're developing the collection, so please keep checking back for updates.</p>"
        },
        {
            name: 'Posters - Dance',
            id: 4,
            src: posterImg,
            alt: 'Poster example - male ballet dancer',
            displayBanner: false,
            layout: 'landscape',
            displayOrder: 1,
            overText: '',
            text: '',
            description: ''
            // bgColor: ''
        }
    ]
}
];