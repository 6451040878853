import { BottomBlockContainer, CopyrightStatement } from "./BottomDisplayBlock.styles";

const BottomDisplayBlock = () => {
const date = new Date();

    return ( 
        <BottomBlockContainer>
            <CopyrightStatement>&copy; {date.getFullYear()} Cuthbert&amp;Co.</CopyrightStatement>
        </BottomBlockContainer>
     );
}
 
export default BottomDisplayBlock;