import { keyframes } from "styled-components";

export const gradualAppear = keyframes`
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
    `;

export const appearFromAbove = keyframes`
    0% {
        transform: translateY(-40px);
    }
    100% {
        transform: translateY(0);
    }
    `;

export const appearFromRight = keyframes`
    0% {
        opacity: 0;
        transform: translateX(100px);
    }
    80% {
        opacity: 0.8;
        transform: translateX(-5px);
    }
    100% {
        opacity: 1;
        transform: translateX(0);
    }
`;

export const appearFromLeft = keyframes`
    0% {
        opacity: 0;
        transform: translateX(-100px);
    }
    80% {
        opacity: 0.8;
        transform: translateX(5px);
    }
    100% {
        opacity: 1;
        transform: translateX(0);
    }
    `;

export const imgAppear = keyframes`
0% {
    opacity: 0;
    transform: translateY(-40px);
}
80% {
    transform: translateY(5px);
}
100% {
    opacity: 1;
    transform: translateY(0);
}
`;

export const imgExpand = keyframes`
0% {
    transform: scale(1);
}
80% {
    transform: scale(1.1);
}
100% {
    transform: scale(1.08);
}
`;