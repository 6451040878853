import styled from "styled-components";

export const BottomBlockContainer = styled.div`
    grid-column: 1/4;
    grid-row: 3;
    font-family: 'Montserrat', sans-serif;
    /* display: block; */
    min-width: 100%;
    background-color: #595857;
    min-height: 100px;
    `;

export const CopyrightStatement = styled.div`
    font-weight: 200;
    text-align: center;
    padding: 20px;
    color: white;
    `;