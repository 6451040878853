import styled from "styled-components";
import { appearFromAbove } from "./Animations.styles";

export const TopFrame = styled.div`
    grid-column: 1/6;
    /* min-width: 100%; */
    background-color: #595857;
    /* background-color: #556; */
    /* min-height: 20px; */
    animation: ${appearFromAbove} 0.6s ease-out;
`;