import { IntroWrapper } from "./Intro.styles";

const Intro = () => {
    return (
        <IntroWrapper>
            <p>A family run &amp; inspired creative company specialising in art with heart!</p>
            <p>Capturing all our loves people, places &amp; pets forever.</p>
            <p>Celebrating our differences and our connection through personalised prints! For
            individuals, couples, families and groups. Available as a framed or unframed print.</p>
        </IntroWrapper>
      );
}
 
export default Intro;