import styled from "styled-components";
import { gradualAppear } from "../Animations.styles";

export const ModalContainer = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.5);
    min-height: 100vh;
    min-width: 100vw;
    animation: ${gradualAppear} 0.6s ease-in-out;
`;

export const ModalImage = styled.img`
        display: block;
        /* max-width: 80vh;
        max-height: 80vh; */
        max-height: 80vh;
        max-width: 80vw;
        /* max-width: 80vh; */
        margin: 5em auto;
        box-shadow: 3px 5px 7px rgba(0,0,0,0.5);
        border: 3px solid white;
        /* animation: appearFromAbove 0.4s ease-out; */
`;

export const ModalText = styled.div`
        display: block;
        border-radius: 10px;
        ${ props => props.bgColor && `background-color: ${props.bgColor};` }
        ${ props => props.textColor && `color: ${props.textColor};` }
        text-align: center;
        /* max-width: 80vh; */
        max-height: 60vh;
        max-width: 80vw;
        overflow-y: auto;
        /* max-width: 80vh; */
        margin: 5em auto;
        padding: 1em;
        box-shadow: 3px 5px 7px rgba(0,0,0,0.5);
        border: 3px solid white;
        /* color: white; */
        font-size: 1.2em;
        line-height: 1.8em;
        /* animation: appearFromAbove 0.4s ease-out; */

        @media (min-width: 18em) {
        margin: 5em auto;
        padding: 1em;
        }

        @media (min-width: 32em ) {
            margin: 5em auto;
            padding: 5em;
        }

        h2 {
            font-family: ltc-broadway,sans-serif;
            text-shadow: 1px 1px #595857;
            font-size: 2em;
            margin-bottom: 1.2em;
        }

        p {
            margin-bottom: 1.6em;
        }
`;