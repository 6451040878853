import styled from "styled-components";
import { gradualAppear} from "../Animations.styles";


export const DisplayGridContainer = styled.ul`
    margin-bottom: 5em;
    list-style: none;
    display: grid;
    /* grid-template-columns: 1fr 1fr 1fr 1fr; */
    grid-template-columns: repeat(4, minmax(5em,1fr));
    /* grid-template-rows: minmax(3em, 1fr)  minmax(3em, 1fr)  minmax(3em, 1fr)  minmax(3em, 1fr); */
    grid-gap: 1.2em;
    row-gap: 5em;
    justify-content: center;
    align-content: center;
    @media (min-width:50em){
        grid-template-columns: repeat(4, minmax(10em,1fr));
    }
    @media (max-width: 25em) {
        grid-template-columns: 1fr;
    }
`;

export const DisplayContainer = styled.li`
    border-radius: 8px;
    text-align: center;
    justify-content: center;
    ${ props => props.banner && 'grid-column: 1/4;' }
    ${ props => props.bgColor && `background-color: ${props.bgColor};` }
    ${ props => props.textColor && `color: ${props.textColor};` }
    /* ${ props => (props.layout === 'landscape') ? 'grid-column: 1/3;' : '' } */
    animation: ${gradualAppear} 0.6s ease-in-out;
    img {
        object-fit: cover;
        object-position: center;
        height: 100%;
        width: 100%;
        max-width: 100%;
        

        transition: transform .2s ease-in-out;
    }

    img:hover {
        transform: scale(1.1);
    }
    img:not(:hover) {
        transform: scale(1);
    }
`;

export const TextWrapper = styled.div`
    font-family: ltc-broadway,sans-serif;
    text-transform: uppercase;
    text-shadow: 1px 1px #595857;
    font-size: 1em;
    font-weight: 200;
    letter-spacing: 0em;
    padding: 50% 0;
    
    transition: transform .2s ease-in-out;

    :hover {
        transform: scale(1.2);
    }
    :not(:hover) {
        transform: scale(1);
    }
    @media (min-width: 50em) {
        font-size: 1.8em;
        letter-spacing: 0.1em;
    }
`;