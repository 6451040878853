import styled from "styled-components";
import {appearFromLeft, appearFromRight, gradualAppear } from "../Animations.styles";

export const TitleContainer = styled.header`
    grid-row: 1;
    grid-column: 1;
    display: grid;
    grid-template-columns: 1fr 2fr;
    grid-template-rows: 1fr 1fr 1fr;
    /* height: 25vh; */
    
    @media (min-width: 50em) {
        grid-template-columns: 1fr 2fr;
        grid-template-rows: 1fr 1fr;
    }
    `;
    
    export const HeaderImageHolder = styled.div`
    grid-row: 2;
    grid-column: 1/3;
    
    @media (min-width: 50em) {
        grid-row: 1/3;
    }

    img {
        object-fit: cover;
        object-position: center;
        height: 100%;
        width: 100%;
        animation: ${gradualAppear} 0.6s ease-in-out;
    }
    `;

export const LogoHolder = styled.div`
    grid-row: 1;
    grid-column: 1/3;
    /* position: absolute; */
    /* top: 40px;
    left: 40px; */
    /* align-content: center; */
    text-align: center;
    margin: auto;
    
    @media (min-width: 50em) {
        /* text-align: right; */
        grid-column: 1;
        margin: auto 5%;
    }
    
    img {
        height: 3.1em;
        animation: ${gradualAppear} 0.6s ease-in-out;
        
        @media (min-width: 50em) {
            height: 5em;
        }
        @media (max-width: 15em) {
            height: 2em;
        }
    }
    h2 {
        font-weight: 400;
        font-size: 1rem;
        letter-spacing: 0.2rem;
        color: #595857;
        animation: ${appearFromLeft} 0.6s ease-out;
        
        @media (min-width: 50em) {
            font-size: 1.2rem;
        }
        @media (max-width: 15em) {
            font-size: 0.8rem;
        }
    }
    
    `;

export const Heading = styled.div`
            grid-column: 1/3;
            grid-row: 3;
            color: #9a9897;
            text-shadow: 1px 1px #595857;
            /* color: #595857; */
            text-align: center;
            animation: ${gradualAppear} 0.6s ease-in-out;
            
            @media (min-width: 50em) {
                grid-column: 1;
                grid-row: 2;
                margin: auto 5%;
            }
            
            h1 {
                font-family: ltc-broadway,sans-serif;
                /* text-transform: uppercase; */
                margin: 2%;
                font-size: 1.8rem;
                /* letter-spacing: 0.1em; */
                font-weight: 400;
                line-height: 1.4em;
                animation: ${appearFromRight} 0.6s ease-out;
                background-color: rgba(252,248,245, 0.7);
                @media (min-width: 50em) {
                    margin: auto 5%;
                }
        }
`;

// export const TitleContainer = styled.div`
//         padding: 20px 0;
//         img {
//             display: block;
//             max-width: 300px;
//             margin: 0 auto 20px 10px;
//             animation: ${gradualAppear} 0.6s ease-in-out;
//         }
//         h1 {
//             background-image: url(${HeaderBanner});
//             background-size: cover;
//             background-position: top;
//             min-height: 360px;
//             width: 100%;
//             position: relative;
//             text-align: left;
//             backface-visibility: hidden;
//             font-size: 1.8rem;
//             font-weight: 300;
//             line-height: 3rem;
//             color: #666;
//             animation: ${gradualAppear} 0.6s ease-in-out;
//         }
        
//         h1 .main {
//             font-family: ltc-broadway,sans-serif;
//             font-weight: 400;
//             font-style: normal;
//             margin: auto 20px;
//             display: block;
//             position: absolute;
//             top: 11%;
//             backface-visibility: hidden;
//             /* transform: translateY(50%); */
//             font-size: 2rem;
//             /* letter-spacing: 0.2rem; */
//             color: #666;
//             animation: ${appearFromLeft} 0.6s ease-out;
//         }
//         h1 .sub {
//             margin: auto 20px;
//             display: block;
//             position: absolute;
//             top: 25%;
//             backface-visibility: hidden;
            
//             font-size: 1.4rem;
//             font-weight: 200;
//             letter-spacing: 0.1rem;
//             color: #666;
//             animation: ${appearFromRight} 0.6s ease-out;
//         }

//         h2 {
//             text-align: center;
//             font-size: 30px;
//             font-weight: 300;
//         }
//     `;