import { ModalContainer, ModalImage, ModalText } from "./Modal.styles";

const Modal = (props) => {

    const {image, text} = props;
    
    // let {src, alt, bgColor, content} = '';
    // if (image) {
    //     console.log(`We're in the Modal IF now ... and it's an ..... IMAGE`);
    //     console.log(`We're in the Modal IF now ... the image.src is: ${image.src}`);
        
    //     ({src, alt} = {image});
    //     console.log(`We're in the Modal IF now ... the SRC is: ${src}`);
    // } else if (text) {
    //     ({bgColor, content} = {text});
    // }
    // console.log(`We're in the Modal now ... the image is:${image}`);
    // console.log(`We're in the Modal now ... the url is:${image.src}`);
    // console.log(image.src);

    // console.log(`We're in the Modal now ... the text is:${text.content}`);
    // console.log(`We're in the Modal now ... the title is:${text.title}`);
    // console.log({...text});
    // console.log(text.bgColor);

    const createMarkUp = () => {
        return {__html: `<h2>${text.title}</h2>${text.content}`}
    }

    const modalClickHandler = () => {
            props.setShowModal(false);
    };

    return ( 
        <ModalContainer onClick={modalClickHandler} >
            { image.src && <ModalImage src={image.src} alt={image.alt} /> }
            { text.bgColor && <ModalText bgColor={text.bgColor} textColor={text.textColor} dangerouslySetInnerHTML={createMarkUp()} /> }

        </ModalContainer>
     );
}
 
export default Modal;